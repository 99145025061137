import React, { useState, useEffect } from "react";
import Logo from "../assets/logo.svg";
import Playstore from "../assets/playstore.svg";
import Appstore from "../assets/appstore.svg";
import "./fit.css";
import { Link } from "react-scroll";

const Navigation = (props) => {
  const [scrolling, setScrolling] = useState(false);

  const [openNav, setopenNav] = useState(true);
  if (openNav) {
    document.body.classList.remove("overflow-hidden");
  } else {
    document.body.classList.add("overflow-hidden");
  }

  useEffect(() => {
    // Function to handle scroll event
    function handleScroll() {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    }

    // Add a scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav className={`p-3 fixed-top nav-color ${scrolling ? "scrolled" : ""}`}>
        <div className="container-fluid container_modified d-flex align-items-center justify-content-between">
          <div className="d-flex w-100 align-items-center  justify-content-between">
            <a href="/" className="d-inline page_logo">
              <span className="nav_logo ">
                <img src={Logo} alt="navlogo" style={{ height: 50 }} />
              </span>
            </a>
            <div
              onClick={() => setopenNav(false)}
              className="d-flex justify-content-evenly c_pointer gap-1 d-inline-block flex-column  d-lg-none opacity-100 align-items-end"
            >
              <span className="menu_line"></span>
              <span className="menu_line_2 "></span>
              <span className="menu_line"></span>
            </div>

            <ul
              className="ps-0 mb-0 d-none d-lg-flex  align-items-center gap-4"
              style={{ listStyle: "none" }}
            >
              <li>
                <Link
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={0} // Offset to adjust the scroll position
                  duration={500}
                  className=" text-decoration-none text-white nav_txt"
                  style={{ cursor: "pointer" }}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={0} // Offset to adjust the scroll position
                  duration={500}
                  className=" text-decoration-none text-white nav_txt"
                  style={{ cursor: "pointer" }}
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="why"
                  spy={true}
                  smooth={true}
                  offset={0} // Offset to adjust the scroll position
                  duration={500}
                  className="text-decoration-none text-white nav_txt"
                  style={{ cursor: "pointer" }}
                >
                  Why Us?
                </Link>
              </li>
              <li class="nav-item dropdown">
                <Link
                  to="feature"
                  spy={true}
                  smooth={true}
                  offset={0} // Offset to adjust the scroll position
                  duration={500}
                  class="dropdown-item text-decoration-none text-white nav_txt"
                  style={{ cursor: "pointer" }}
                >
                  Features
                </Link>
                {/* <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link
                    to="feature"
                    spy={true}
                    smooth={true}
                    offset={0} // Offset to adjust the scroll position
                    duration={500}
                    class="dropdown-item text-decoration-none text-white nav_txt"
                    style={{ cursor: "pointer" }}
                  >
                    Exclusive Features
                  </Link>
                  <br />
                  <Link
                    to="tool"
                    spy={true}
                    smooth={true}
                    offset={0} // Offset to adjust the scroll position
                    duration={500}
                    class="dropdown-item text-decoration-none text-white nav_txt"
                    style={{ cursor: "pointer" }}
                  >
                    Tools & Insights
                  </Link>
                  <div class="dropdown-divider"></div>
                  <Link
                    to="coming"
                    spy={true}
                    smooth={true}
                    offset={0} // Offset to adjust the scroll position
                    duration={500}
                    class="dropdown-item"
                    href="#"
                    style={{ cursor: "pointer" }}
                  >
                    Coming Soon
                  </Link>
                </div> */}
              </li>

              <li>
                <a href="." className="text-decoration-none text-white">
                  <img
                    src={Playstore}
                    alt="Play Store"
                    style={{ height: 40 }}
                  />
                </a>
              </li>
              <li>
                <a href="." className="text-decoration-none text-white">
                  <img src={Appstore} alt="App Store" style={{ height: 40 }} />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div
          className={
            openNav ? "showNav d-lg-none d-block" : "hideNav d-lg-none d-block"
          }
        >
          <ul className="ps-0 mb-0 position-relative d-flex gap-4 justify-content-center w-100 min-vh-100 flex-column align-items-center">
            <button
              onClick={() => {
                setopenNav(true);
              }}
              className="btn btn-close  bg-white opacity-100  position-absolute top-0 end-0  me-4"
            ></button>

            <li onClick={() => setopenNav(true)}>
              <Link
                to="home"
                spy={true}
                smooth={true}
                offset={0} // Offset to adjust the scroll position
                duration={500}
                className=" text-decoration-none text-white nav_txt"
                style={{ cursor: "pointer" }}
              >
                Home
              </Link>
            </li>

            <li onClick={() => setopenNav(true)}>
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={0} // Offset to adjust the scroll position
                duration={500}
                className=" text-decoration-none text-white nav_txt"
                style={{ cursor: "pointer" }}
              >
                About
              </Link>
            </li>
            <li onClick={() => setopenNav(true)}>
              <Link
                to="why"
                spy={true}
                smooth={true}
                offset={0} // Offset to adjust the scroll position
                duration={500}
                className="text-decoration-none text-white nav_txt"
                style={{ cursor: "pointer" }}
              >
                Why Us?
              </Link>
            </li>
            <li onClick={() => setopenNav(true)}>
              <Link
                to="feature"
                spy={true}
                smooth={true}
                offset={0} // Offset to adjust the scroll position
                duration={500}
                className="text-decoration-none text-white nav_txt"
                style={{ cursor: "pointer" }}
              >
                Features
              </Link>
            </li>

            <li onClick={() => setopenNav(true)}>
              <img className="w_logo" src={Playstore} alt="Play Store" />
            </li>
            <li onClick={() => setopenNav(true)}>
              <img className="w_logo" src={Appstore} alt="App Store" />
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
