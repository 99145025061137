import React from "react";

function Terms() {
  return (
    <>
      <div style={{ backgroundColor: "#121212", padding: 15 }}>
        <div style={{ backgroundColor: "#121212" }} className="container">
          <div className="row">
            <p>
              Welcome to the Fitreat application (the "App"). By using the App,
              you agree to the following terms and conditions:
            </p>
            <ol style={{ color: "#ffffff" }}>
              <li>
                <strong>Age Requirement</strong>
                <br />
                You must be at least 18 years old to use the App. If you are
                under 18, you may use the App only with the supervision and
                consent of a parent or legal guardian.
              </li>
              <br />
              <li>
                <strong>Personal Use Only</strong>
                <br />
                You may only use the App for your personal, non-commercial use.
                The content and features provided are intended solely for
                individual users to manage their dietary preferences and
                progress.
              </li>
              <br />
              <li>
                <strong>Account Information</strong>
                <br />
                You are responsible for maintaining the confidentiality of your
                account information and password. You may not share your account
                information with others. You are solely responsible for all
                activities conducted under your account.
              </li>
              <br />
              <li>
                <strong>Medical Disclaimer</strong>
                <br />
                The diet recommendations provided by the App are generated by AI
                and are for informational purposes only. You must consult your
                doctor or a healthcare provider before making any changes to
                your regular diet, especially if you are suffering from any
                disease or medical condition. Fitreat does not provide medical
                advice, diagnosis, or treatment.
              </li>
              <br />
              <li>
                <strong>No Harm or Fraud</strong>
                <br />
                You may not use the App to harm or defraud anyone, violate any
                laws or regulations, or engage in any illegal or unethical
                activities.
              </li>
              <br />
              <li>
                <strong>Changes to Diet and Health</strong>
                <br />
                Any modifications to your diet based on the App's
                recommendations should be discussed with a healthcare
                professional. Fitreat is not responsible for any negative health
                effects that may arise from following the diet recommendations
                without consulting a doctor or nutritionist.
              </li>
              <br />
              <li>
                <strong>Suspension and Termination</strong>
                <br />
                We reserve the right to suspend or terminate your access to the
                App at any time for any reason, including but not limited to
                violating these terms and conditions.
              </li>
              <br />
              <li>
                <strong>Use of the App</strong>
                <br />
                The App is provided on an "as is" and "as available" basis. We
                do not make any warranties or representations, express or
                implied, as to the operation of the App or the accuracy of the
                diet recommendations.
                <br />
                We are not responsible for any errors, omissions, or damages
                arising out of your use of the App.
              </li>
              <br />
              <li>
                <strong>Intellectual Property</strong>
                <br />
                The App and its contents, including but not limited to text,
                images, code, and design, are protected by copyright and other
                intellectual property laws. You may not copy, modify,
                distribute, or transmit any part of the App without our express
                written permission.
              </li>
              <br />
              <li>
                <strong>Disclaimer of Warranties</strong>
                <br />
                TO THE EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
                EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                AND NON-INFRINGEMENT.
              </li>
              <br />
              <li>
                <strong>Limitation of Liability</strong>
                <br />
                TO THE EXTENT PERMITTED BY LAW, WE SHALL NOT BE LIABLE FOR ANY
                DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
                EXEMPLARY DAMAGES ARISING OUT OF YOUR USE OF THE APP, EVEN IF WE
                HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              </li>
              <br />
              <li>
                <strong>Governing Law</strong>
                <br />
                These terms and conditions shall be governed by and construed in
                accordance with the laws of the State of California, without
                regard to its conflict of law provisions.
              </li>
              <br />
              <li>
                <strong>Entire Agreement</strong>
                <br />
                These terms and conditions constitute the entire agreement
                between you and us with respect to your use of the App.
              </li>
              <br />
              <li>
                <strong>Severability</strong>
                <br />
                If any provision of these terms and conditions is held to be
                invalid or unenforceable, such provision shall be struck from
                these terms and conditions, and the remaining provisions shall
                remain in full force and effect.
              </li>
              <br />
              <li>
                <strong>Waiver</strong>
                <br />
                No waiver of any provision of these terms and conditions shall
                be effective unless in writing and signed by both you and us.
              </li>
              <br />
              <li>
                <strong>Notices</strong>
                <br />
                All notices and communications under these terms and conditions
                shall be in writing and deemed duly given when delivered in
                person, via certified or registered mail, or via email to the
                following address:
                <br />
                <br />
                If to us:
                <br />
                <strong>HelixSmartLabs</strong>
                <br />
                New Gen IEDC, Manav Rachna, Sector 43, Suraj Kund Road
                <br />
                <br />
                If to you:
                <br />
                [Your Name]
                <br />
                [Your Address]
              </li>
              <br />
              <li>
                <strong>Changes to These Terms and Conditions</strong>
                <br />
                We reserve the right to change these terms and conditions at any
                time. Any changes will be posted on the App and will take effect
                immediately.
              </li>
              <br />
              <li>
                <strong>Your Acceptance of These Terms</strong>
                <br />
                By using the App, you agree to these terms and conditions. If
                you do not agree, please do not use the App.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
}

export default Terms;
