import React from "react";
import PhoneResize from "../assets/why.png";
import FitGreen from "../assets/fit-green.svg";
import FitBlack from "../assets/fit-black.svg";
function Why() {
  return (
    <div className="row" style={{ backgroundColor: "white" }}>
      <div
        data-aos="fade-up"
        data-aos-duration="3000"
        className="col-xl-6 remove-left-right-100"
        style={{ padding: "50px 100px 50px 100px" }}
      >
        <div
          className="line-height-0-all"
          style={{ display: "flex", flexWrap: "wrap", justifyContent:"center" }}
        >
          <span
            className="line-height-0 why"
            style={{
              fontFamily: "black-mango",
              fontSize: 60,
              color: "black",
              lineHeight: 2.5,
            }}
          >
            Why
          </span>
          &nbsp;
          <span
            className="line-height-0-choose why"
            style={{
              fontFamily: "black-mango",
              fontSize: 90,
              color: "#1a6e60",
            }}
          >
            Choose
          </span>
          &nbsp;
          <span
            className="line-height-0-us why"
            style={{
              fontFamily: "black-mango",
              fontSize: 60,
              color: "black",
              lineHeight: 2.5,
            }}
          >
            Us?
          </span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: 25,
          }}
        >
          <div>
            <img src={FitGreen} alt="" />
          </div>
          <div
            style={{
              padding: "0 25px",
              fontSize: 32,
              color: "black",
              fontFamily: "black-mango",
              fontWeight: "bold",
            }}
          >
            Personalized Guidance
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: "#f2f3ed",
            padding: 25,
            borderRadius: 10,
          }}
        >
          <div>
            <img src={FitBlack} alt="" />
          </div>
          <div
            style={{
              padding: "0 25px",
              fontSize: 32,
              color: "#1a6e60",
              fontFamily: "black-mango",
              fontWeight: "bold",
              borderRadius: 10,
            }}
          >
            Cost-Efficient
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: 25,
          }}
        >
          <div>
            <img src={FitGreen} alt="" />
          </div>
          <div
            style={{
              padding: "0 25px",
              fontSize: 32,
              color: "black",
              fontFamily: "black-mango",
              fontWeight: "bold",
            }}
          >
            Adaptive Needs
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: "#f2f3ed",
            padding: 25,
            borderRadius: 10,
          }}
        >
          <div>
            <img src={FitBlack} alt="" />
          </div>
          <div
            style={{
              padding: "0 25px",
              fontSize: 32,
              color: "#1a6e60",
              fontFamily: "black-mango",
              fontWeight: "bold",
              borderRadius: 10,
            }}
          >
            Simplified Nutrition
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: 25,
          }}
        >
          <div>
            <img src={FitGreen} alt="" />
          </div>
          <div
            style={{
              padding: "0 25px",
              fontSize: 32,
              color: "black",
              fontFamily: "black-mango",
              fontWeight: "bold",
            }}
          >
            Effortless Tracking
          </div>
        </div>
      </div>
      <div
        className="col-xl-6 remove-below-1200"
        data-aos="fade-left"
        data-aos-duration="3000"
        style={{
          backgroundImage: `url(${PhoneResize})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      ></div>
    </div>
  );
}

export default Why;
