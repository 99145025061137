import React from "react";
import AboutPic from "../assets/about-mobile.png";
import BackgroundFit from "../assets/about-fitreat-background-2percent.svg";
import "./fit.css";
function About() {
  return (
    <div className="row" style={{ minHeight: "100vh" }}>
      <div
        data-aos="fade-up"
        data-aos-duration="3000"
        className="col-xl-6 col-lg-12 col-md-12 "
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={AboutPic} alt="" style={{ width: "100%", maxWidth: 700 }} />
      </div>
      <div
        data-aos="fade-up"
        data-aos-duration="3000"
        className="col-xl-6 col-lg-12 col-md-12"
        style={{
          backgroundImage: `url(${BackgroundFit})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: 25,
        }}
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <span
            className="about_txt"
            style={{ fontFamily: "black-mango", fontSize: 60, lineHeight: 2.5 }}
          >
            About
          </span>
          &nbsp;&nbsp;
          <span
            style={{
              fontFamily: "black-mango",
              fontSize: 90,
              color: "#53b951",
            }}
          >
            Fitreat
          </span>
        </div>

        <p
          style={{
            fontFamily: "roboto-slab",
            fontWeight: "light",
            fontSize: 28,
          }}
        >
          Fitreat, powered by AI trained on a vast database of diet plans and
          nutritional data, exists to offer precise nutrition. Whether your aim
          is weight loss, maintenance, or gaining, Fitreat tailors a diet that
          aligns with your unique daily routine and lifestyle.
        </p>
        <p
          style={{
            fontFamily: "roboto-slab",
            fontWeight: "light",
            fontSize: 28,
          }}
        >
          Say goodbye to unnecessary monthly dietitian subscriptions or diet app
          expenses. Fitreat is your comprehensive solution, whether you're an
          athlete with rigorous goals, facing health challenges, or simply
          pursuing weight loss and muscle gain. Fitreat deciphers your
          biochemistry, recommending the optimal diet, all on a completely free
          platform that simplifies calories.
        </p>
      </div>
    </div>
  );
}

export default About;
