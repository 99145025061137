import React, {  useEffect } from "react";
import Navigation from "../fitreatComponent/Navigation";
import Header from "../fitreatComponent/Header";
import Services from "../fitreatComponent/Services";
import About from "../fitreatComponent/About";
import Why from "../fitreatComponent/Why";
import Testimonials from "../fitreatComponent/Testimonials";
import Features from "../fitreatComponent/Features";
import ToolsInsights from "../fitreatComponent/ToolsInsights";
import ComingSoon from "../fitreatComponent/ComingSoon";
import Highlights from "../fitreatComponent/Highlights";
import FAQ from "../fitreatComponent/Faq";
import Footer from "../fitreatComponent/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { TabTitle } from "./DynamicTitle";
import { 
  Element, 
  // scroller 
} from "react-scroll";

// import TestVector from "../assets/test-vector.svg";

function Fitreat() {
  TabTitle("Fitreat.ai");

  // const scrollToSection = (section) => {
  //   scroller.scrollTo(section, {
  //     duration: 800,
  //     delay: 0,
  //     smooth: "easeInOutQuart",
  //   });
  // };

  useEffect(() => {
    AOS.init({
      once: false,
    });
    if (window) {
      window.addEventListener(
        "wheel",
        () => {
          AOS.refresh();
        },
        false
      );
    }
    AOS.refresh();
  }, []);
  return (
    <>
      <div
        className="container-fluid"
        style={{
          backgroundColor: "#121212",
          minHeight: "100vh",
          fontFamily: "roboto-slab",
          fontSize: 28,
          overflow: "hidden",
        }}
      >
        <Navigation />
        <Element name="home">
          <Header />
        </Element>

        <Services />

        <Element name="about">
          <About />
        </Element>
        <Element name="why">
          <Why />
        </Element>
        {/* Pending */}

        <Testimonials />
        <Element name="feature">
          <Features />
        </Element>
        <Element name="tool">
          <ToolsInsights />
        </Element>
        <Element name="coming">
          <ComingSoon />
        </Element>
        {/* <div
          style={{
            backgroundColor: "red",
            width: 500,
            height: 500,
            clipPath: "polygon(0% 0%, 100% 33%, 89% 100%, 0% 80%)",
          }}
        >
          <img src={TestVector} />
        </div> */}
        <Highlights />

        <FAQ />

        <Footer />
      </div>
    </>
  );
}

export default Fitreat;
