import React from "react";

function Privacy() {
  return (
    <div style={{ backgroundColor: "#121212", padding: 15 }}>
      <div style={{ backgroundColor: "#121212" }} className="container">
        <div className="row">
          <p>
            <b>Information We Collect:</b>
            <br />
            <span style={{ fontSize: "1rem" }}>
              We may collect the following personal information from you:
            </span>
          </p>
          <ul>
            <li>Name</li>
            <li>Phone number</li>
            <li>Email address</li>
            <li>Dietary preferences</li>
            <li>Health-related information (optional)</li>
          </ul>
          <p>
            How We Use Your Information
            <br />
            <span style={{ fontSize: "1rem" }}>
              We use your personal information to:
            </span>
          </p>
          <ul>
            <li>Create and manage your Fitreat account</li>
            <li>
              Provide personalized diet recommendations based on your
              preferences and health data
            </li>
            <li>Send notifications related to your account and progress</li>
            <li>Improve the app's functionality and user experience</li>
          </ul>
          <p>
            Health Data Disclaimer
            <br />
            <span style={{ fontSize: "1rem" }}>
              Any health-related data provided by you is voluntary and used to
              offer more accurate diet recommendations. This data is never used
              for medical purposes, and we recommend consulting with a
              healthcare provider before following any diet recommendations.
            </span>
          </p>
          <p>
            How We Disclose Your Information
            <br />
            <span style={{ fontSize: "1rem" }}>
              We may disclose your personal information to:
            </span>
          </p>
          <ul>
            <li>
              Vendors providing services through the App Razorpay, our payment
              processor
            </li>
            <li>Third-party service providers who help operate the App</li>
          </ul>

          <p>We do not sell your personal information to third parties.</p>
          <p>
            Your Choices You have the following options regarding your personal
            information:
          </p>
          <ul>
            <li>
              You can choose not to provide certain personal information, but
              this may limit your use of some features of the App.
            </li>
            <li>
              You can access and update your personal information by logging
              into your account.
            </li>
            <li>
              You can request that we delete your personal information by
              contacting us at <b>info.fitreat@gmail.com.</b>
            </li>
          </ul>
          <p>
            <b>Data Security:</b> <br />
            <span style={{ fontSize: "1rem" }}>
              We take reasonable precautions to protect your personal
              information from unauthorized access or disclosure. However, no
              method of data security is 100% secure, and we cannot guarantee
              the absolute security of your information.
            </span>
          </p>
          <p style={{ fontSize: "1rem" }}>
            Changes to This Privacy Policy We may update this privacy policy
            from time to time. Any changes will be posted on the App, and the
            updated policy will take effect immediately.
          </p>
          <p style={{ fontSize: "1rem" }}>
            <b>Contact Us:</b> If you have any questions about this privacy
            policy, please contact us at{" "}
            <span style={{ color: "#53b951" }}>info.fitreat@gmail.com</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
