import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageNotFound from "./Pages/PageNotFound";
import ScrollToTop from "./components/ScrollToTop";
import Fitreat from "./Pages/Fitreat";
import ContactUs from "./Pages/ContactUs";
import Career from "./Pages/Career";
import Privacy from "./Pages/Privacy";
import Terms from "./Pages/Terms";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsConditions from "./Pages/TermsConditions";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init({
      once: false,
    });
    if (window === Object) {
      window.addEventListener(
        "wheel",
        () => {
          AOS.refresh();
        },
        false
      );
    }
    AOS.refresh();
  }, []);
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/" exact component={Fitreat} />
        <Route path="/terms" exact component={Terms} />
        <Route path="/terms-conditions" exact component={TermsConditions} />
        <Route path="/privacy" exact component={Privacy} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route path="/careers" exact component={Career} />
        <Route path="/contact-us" exact component={ContactUs} />
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
}

export default App;
