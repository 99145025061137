import React from "react";
import FeaturesPic from "../assets/features.png";

function Features() {
  return (
    <div
      className="row"
      style={{ minHeight: "100vh", paddingTop: 100, paddingBottom: 50 }}
    >
      <div className="col-md-4">
        <div data-aos="fade-right" data-aos-duration="3000" className="row">
          <div
            className="col-md-8 offset-md-2"
            style={{
              backgroundColor: "#1e1e1e",
              borderRadius: 8,
              padding: 35,
            }}
          >
            Thousands of Meal Plan
            <p
              style={{
                fontSize: 18,
                fontFamily: "roboto-slab",
                padding: 0,
              }}
            >
              Discover a vast selection of personalized meal plans designed to
              match your tastebuds, ensuring you never get bored
              of your diet.
            </p>
            <button
              style={{
                fontFamily: "black-mango",
                fontWeight: "bold",
                fontSize: 21,
                color: "white",
                backgroundColor: "#1e1e1e",
                border: "1px solid #53b951",
                borderRadius: 8,
                padding: 5,
                marginTop: 10,
              }}
            >
              + Know More
            </button>
          </div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-duration="3000"
          className="row removeMargin30"
          style={{ marginTop: "30%" }}
        >
          <div
            className="col-md-8 offset-md-2"
            style={{
              borderRadius: 8,
              padding: 35,
            }}
          >
            Sick mode for nutrition
            <p
              style={{
                fontSize: 18,
                fontFamily: "roboto-slab",
                padding: 0,
              }}
            >
              When you're not feeling your best, our Sick Mode ensures
              you receive the most suitable dietary guidance to aid in your
              recovery.
            </p>
            <button
              style={{
                fontFamily: "black-mango",
                fontWeight: "bold",
                fontSize: 21,
                color: "white",
                backgroundColor: "#1e1e1e",
                border: "1px solid #53b951",
                borderRadius: 8,
                padding: 5,
                marginTop: 10,
              }}
            >
              + Know More
            </button>
          </div>
        </div>
      </div>
      <div
        data-aos="fade-up"
        data-aos-duration="3000"
        className="col-md-4 removePicBelow768"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          padding: 50,
          backgroundImage: `url(${FeaturesPic})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        {/* <img src={Features} alt="" style={{height:"90vh"}}/> */}
      </div>
      <div className="col-md-4">
        <div
          data-aos="fade-left"
          data-aos-duration="3000"
          className="row removeMargin30"
          style={{ marginTop: "30%" }}
        >
          <div
            className="col-md-8 offset-md-2"
            style={{
              borderRadius: 8,
              padding: 35,
            }}
          >
            Achieve goals
            <p
              style={{
                fontSize: 18,
                fontFamily: "roboto-slab",
                padding: 0,
              }}
            >
              Whether it's weight loss, maintenance, or gain, Fitreat.ai
              provides tailored plans to help you realize your fitness objectives effectively.
            </p>
            <button
              style={{
                fontFamily: "black-mango",
                fontWeight: "bold",
                fontSize: 21,
                color: "white",
                backgroundColor: "#1e1e1e",
                border: "1px solid #53b951",
                borderRadius: 8,
                padding: 5,
                marginTop: 10,
              }}
            >
              + Know More
            </button>
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="3000"
          className="row removeMargin30"
          style={{ marginTop: "30%" }}
        >
          <div
            className="col-md-8 offset-md-2"
            style={{
              backgroundColor: "#1e1e1e",

              borderRadius: 8,
              padding: 35,
            }}
          >
            Share progress with friends
            <p
              style={{
                fontSize: 18,
                fontFamily: "roboto-slab",
                padding: 0,
              }}
            >
              Foster a sense of community and motivation by sharing your fitness
              journey, milestones, and challenges with friends, creating a
              support network to keep you on track.
            </p>
            <button
              style={{
                fontFamily: "black-mango",
                fontWeight: "bold",
                fontSize: 21,
                color: "white",
                backgroundColor: "#1e1e1e",
                border: "1px solid #53b951",
                borderRadius: 8,
                padding: 5,
                marginTop: 10,
              }}
            >
              + Know More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
