import React from "react";
import Insta from "../assets/instagram.svg";
import X from "../assets/x.svg";
import Youtube from "../assets/youtube.svg";
import Logo from "../assets/logo.svg";
function Footer() {
  return (
    <>
      <div className="row">
        <div className="col-md-4 offset-md-4" style={{ padding: 50 }}>
          <img src={Logo} alt="" style={{ width: "100%" }} />
        </div>
      </div>
      <div className="row">
        <div
          className="col-md-4 offset-md-4"
          style={{
            paddingBottom: 50,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <img src={Insta} alt="" />
          <img src={X} alt="" />
          <img src={Youtube} alt="" />
        </div>
      </div>
      <div style={{ border: "1px solid #f2f2f2" }}></div>
      <div className="row py-2">
        <div className="col-md-4">
          <center>
            <p
              style={{
                fontWeight: 300,
                color: "white",
                fontSize: 16,
                padding: 0,
              }}
            >
              Copyright &copy; HelixSmartLabs
            </p>
          </center>
        </div>
        <div
          className="col-md-8 remove-footer-576"
          style={{
            fontWeight: 300,
            fontSize: 18,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            padding: 0,
            alignItems: "center",
          }}
        >
          {/* <span style={{ margin: 0, padding: 0, cursor: "pointer" }}>Blog</span> */}
          {/* <span style={{ margin: 0, padding: 0, cursor: "pointer" }}>
            Our Team
          </span> */}
          <a style={{ textDecoration: "none", color: "white" }} href="/careers">
            <span style={{ margin: 0, padding: 0, cursor: "pointer" }}>
              Career
            </span>
          </a>
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="/contact-us"
          >
            <span style={{ margin: 0, padding: 0, cursor: "pointer" }}>
              Contact Us
            </span>
          </a>
          <a style={{ textDecoration: "none", color: "white" }} href="/privacy-policy">
            <span style={{ margin: 0, padding: 0, cursor: "pointer" }}>
              Privacy Policy
            </span>
          </a>
          <a style={{ textDecoration: "none", color: "white" }} href="/terms-conditions">
            <span style={{ margin: 0, padding: 0, cursor: "pointer" }}>
              Terms & Conditions
            </span>
          </a>
        </div>
      </div>
    </>
  );
}

export default Footer;
