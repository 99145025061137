import React from "react";
import Coming from "../assets/coming.jpg";

function ComingSoon() {
  return (
    <div className="row" style={{ backgroundColor: "white" }}>
      <div
        className="col-md-6"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <img src={Coming} alt="" style={{ width: "100%" }} />
      </div>
      <div
        className="col-md-5"
        style={{
          color: "black",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            margin: 0,
            padding: 0,
            fontSize: 48,
            fontFamily: "black-mango",
          }}
        >
          CHALLENGE A FRIEND
        </p>
        <p
          style={{
            margin: 0,
            padding: 0,
            fontSize: 32,
            fontFamily: "roboto-slab",
          }}
        >
          Make fitness fun and engaging by challenging a friend to meet your fitness goals together.
        </p>
      </div>
    </div>
  );
}

export default ComingSoon;
