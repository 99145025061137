import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import ContactHeader from "../components/ContactPageComp/ContactHeader";
import ContactForm from "../components/ContactPageComp/ContactForm";
import SocialBlock from "../components/ContactPageComp/SocialBlock";
import { TabTitle } from "./DynamicTitle";
// const sgMail = require('@sendgrid/mail');

const ContactUs = () => {
  TabTitle("Contact | HSL");
  return (
    <>
      <Nav />
      <div>
        <ContactHeader />
        <ContactForm />
        <h1 className="my-5" style={{ color: "black", textAlign: "center" }}>
          Know More about HelixSmartLabs
        </h1>
        <SocialBlock />
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
