import React from "react";
import One from "../assets/1.jpg";
import Two from "../assets/2.jpg";
import Three from "../assets/3.jpg";
function ToolsInsights() {
  return (
    <>
      <div className="row" style={{ backgroundColor: "white" }}>
        <div className="flex_row_center_center remove-flex_row_center_center  mt-5">
          <span
            style={{
              fontSize: 90,
              fontFamily: "black-mango",
              color: "#1a6e60",
            }}
            className="tools-insights-text"
          >
            Improved Tools
          </span>
          &nbsp;&nbsp;
          <span
            style={{
              fontSize: 90,
              fontFamily: "black-mango",
              color: "black",
            }}
            className="tools-insights-text"
          >
            &
          </span>
          &nbsp;&nbsp;
          <span
            style={{
              fontSize: 90,
              fontFamily: "black-mango",
              color: "#1a6e60",
            }}
            className="tools-insights-text"
          >
            Better Insights
          </span>
        </div>
      </div>
      <div
        className="row d-flex justify-content-around"
        style={{
          backgroundColor: "white",
          padding: "50px 0px",
        }}
      >
        <div
          data-aos="fade-right"
          data-aos-duration="1500"
          className="col-xl-3 col-lg-5 col-md-5 mt-5"
          style={{
            border: "2px solid #1a6e60",
            padding: 0,
            backgroundColor: "#f2f3ed",
            borderRadius: "10px 10px 10px 10px",
            overflow:"hidden"
          }}
        >
          <img src={One} alt="" style={{ width: "100%" }} />
          <div style={{ padding: "20px 30px" }}>
            <center>
              <p
                style={{
                  fontFamily: "black-mango",
                  fontSize: 36,
                  color: "black",
                  margin: 0,
                  padding: 0,
                }}
              >
                Food Tracking
              </p>
            </center>

            <p
              style={{
                fontFamily: "roboto-slab",
                fontSize: 24,
                color: "black",
                margin: 0,
                padding: 0,
                textAlign: "center",
              }}
            >
              Seamlessly monitor your daily nutrition intake, making informed
              choices for a healthier you.
            </p>
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="3000"
          className="col-xl-3 col-lg-5 col-md-5  mt-5"
          style={{
            border: "2px solid #1a6e60",
            padding: 0,
            backgroundColor: "#f2f3ed",
            borderRadius: "10px 10px 10px 10px",
            overflow:"hidden"
          }}
        >
          <img src={Two} alt="" style={{ width: "100%" }} />
          <div style={{ padding: "20px 30px" }}>
            <center>
              <p
                style={{
                  fontFamily: "black-mango",
                  fontSize: 36,
                  color: "black",
                  margin: 0,
                  padding: 0,
                }}
              >
                Body Matrices
              </p>
            </center>

            <p
              style={{
                fontFamily: "roboto-slab",
                fontSize: 24,
                color: "black",
                margin: 0,
                padding: 0,
                textAlign: "center",
              }}
            >
              Keep tabs on your progress with detailed body measurements,
              empowering your fitness journey.
            </p>
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1500"
          className="col-xl-3 col-lg-5 col-md-5  mt-5"
          style={{
            border: "2px solid #1a6e60",
            padding: 0,
            backgroundColor: "#f2f3ed",
            borderRadius: "10px 10px 10px 10px",
            overflow:"hidden"
          }}
        >
          <img src={Three} alt="" style={{ width: "100%" }} />
          <div style={{ padding: "20px 30px" }}>
            <center>
              <p
                style={{
                  fontFamily: "black-mango",
                  fontSize: 36,
                  color: "black",
                  margin: 0,
                  padding: 0,
                }}
              >
                Streak Motivation
              </p>
            </center>

            <p
              style={{
                fontFamily: "roboto-slab",
                fontSize: 24,
                color: "black",
                margin: 0,
                padding: 0,
                textAlign: "center",
              }}
            >
              Stay motivated with a streak that rewards consistent food
              logging, helping you build better habits.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ToolsInsights;
