import axios from "axios";
import React, { useState } from "react";
const ContactForm = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    phoneno: "",
    subject: "",
    company: "",
    message: "",
  });
  const handleChange = (e) => {
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
    console.log(values);
  };
  const handleSubmit = async () => {
    try {
      await axios.post("https://api.helixsmartlabs.in", values);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="row contactForm">
      <div
        className="col-md-7 p-3"
        style={{ backgroundColor: "white", color: "black" }}
      >
        <div className="m-5">
          <div className="mb-3">
            <input
              class="form-control"
              onChange={handleChange}
              type="text"
              name="name"
              id="name"
              placeholder="Enter Value"
              required
            />
            <label class="form-label activeLabel" htmlFor="name">
              Name<span style={{ color: "red" }}>*</span>
            </label>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <input
                class="form-control"
                onChange={handleChange}
                name="email"
                type="email"
                id="e-mail"
                placeholder="Enter Value"
                required
              />
              <label class="form-label activeLabel" htmlFor="e-mail">
                Email ID<span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="col-md-6">
              <input
                class="form-control"
                onChange={handleChange}
                name="phoneno"
                type="text"
                id="phone"
                placeholder="Enter Value"
                required
              />
              <label class="form-label activeLabel" htmlFor="phone">
                Phone no.<span style={{ color: "red" }}>*</span>
              </label>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <input
                class="form-control"
                onChange={handleChange}
                name="subject"
                type="text"
                id="subject"
                placeholder="Enter Value"
                required
              />
              <label class="form-label activeLabel" htmlFor="subject">
                Subject<span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="col-md-6">
              <input
                class="form-control"
                onChange={handleChange}
                name="company"
                type="text"
                id="company"
                placeholder="Enter Value"
              />
              <label class="form-label activeLabel" htmlFor="company">
                Company
              </label>
            </div>
          </div>
          <div className="mb-3">
            <label class="form-label" htmlFor="message">
              Message<span style={{ color: "red" }}>*</span>
            </label>
            <textarea
              class="form-control"
              onChange={handleChange}
              name="message"
              type="text"
              id="message"
              placeholder="Enter Value"
              rows="5"
              required
            />
          </div>
          <button
            onClick={() => {
              handleSubmit();
            }}
            className="button"
            style={{ minWidth: "10em" }}
          >
            Submit
            <span>
              {/* <b>&gt;</b> */}
              <i className="arrow right-arrow"></i>
            </span>
          </button>
        </div>
      </div>
      <div
        className="col-md-5 p-5"
        style={{ backgroundColor: "rgb(31,31,31)" }}
      >
        <div className="mb-3">
          <div style={{ color: "rgb(112,112,112)" }}>Contact at</div>
          <div>+91 9873932539</div>
        </div>
        <div className="mb-3">
          <div style={{ color: "rgb(112,112,112)" }}>Mail Us at</div>
          <div>contact@helixsmartlabs.in</div>
        </div>
        <div className="mb-3">
          <div style={{ color: "rgb(112,112,112)" }}>Enquiries</div>
          <div>info@helixsmartlabs.in</div>
        </div>
        <div className="mb-3">
          <div style={{ color: "rgb(112,112,112)" }}>Locate Us at</div>
          <div>
            Manav Rachna Campus
            <br />
            Sector - 46, Faridabad
            <br />
            Haryana, India -121006
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
